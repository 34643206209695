
export default {
  props: {
    value: Boolean,
    expandableHeight: String,
  },
  data() {
    return {
      isOverflowVisible: this.value
    }
  },
  watch: {
    value(value) {
      // fix for overflow on filters, timeout is transition duration
      setTimeout(() => this.isOverflowVisible = value, 300);
    }
  },
  methods: {
    toggleExpander() {
      this.$emit('input', !this.value);
    }
  }
}
