
import FEEDBACK from "@/apollo/mutations/feedback";
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    offer: Object,
    onClick: Function
  },
  data() {
    return {
      callbackOption: null,
      phone: null,
      error: '',
      name: null,
      timeArray: [
        'с 9:00 до 12:00',
        'с 12:00 до 17:00',
        'c 17:00 до 21:00'
      ],
      isVisible:{
        time:false
      },
    }
  },
  computed: {
    ...mapGetters({
      site_id: "site_id",
    })
  },
  methods: {
    ...mapActions({
      openModal: 'modal/modal-main/openModal',
      sendForm: 'form/form/sendForm',
      closeModal: 'modal/modal-main/closeModal'
    }),
    checkForm() {
      if (!this.name) {
        this.error = 'name';
        return false;
      }
      if (!this.phone || this.phone.includes('_')) {
        this.error = 'phone';
        return false;
      }
      return true;
    },
    async sendData({
                     name = this.name, phone = this.phone, callbackOption = this.callbackOption, comment, check = true
                   } = {}) {
      // if (this.checkForm()) {
        let formData = {
          type: 'free-selection',
          client_name: name,
          client_phone: phone,
          comment: callbackOption ? `Перезвонить: ${callbackOption}. Комментарий: ${comment ? comment : 'нет'}.`: null
        }
        // utm
        if (localStorage.utm_source) {
          formData.utm_source = localStorage.utm_source
        }
        if (localStorage.utm_medium) {
          formData.utm_medium = localStorage.utm_medium
        }
        if (localStorage.utm_campaign) {
          formData.utm_campaign = localStorage.utm_campaign
        }
        if (localStorage.utm_term) {
          formData.utm_term = localStorage.utm_term
        }
        if (localStorage.utm_content) {
          formData.utm_content = localStorage.utm_content
        }
        await this.sendForm(formData)
        await this.closeModal()
      // if (check ? this.checkForm(): true) {
      //   let formData = {
      //     site_id: this.site_id,
      //     type: "FREE-SELECTION",
      //     // client_vehicle_mark: this.offer && this.offer.mark.title,
      //     // client_vehicle_model: this.offer && this.offer.folder.title,
      //     // client_vehicle_run: this.offer && this.offer.run.toString(),
      //     // client_vehicle_price: this.cost,
      //     // client_vehicle_year: this.offer && this.offer.year.toString(),
      //     client_name: name,
      //     client_phone: phone,
      //     utm_source: localStorage.utm_source || "",
      //     utm_medium: localStorage.utm_medium || "",
      //     utm_campaign: localStorage.utm_campaign || "",
      //     utm_term: localStorage.utm_term || "",
      //     utm_content: localStorage.utm_content || "",
      //     comment: `${callbackOption ? 'Когда Вам перезвонить?' + callbackOption.title + '.' : ''} ${comment || ''}`
      //   };
      //   try {
      //     const res = await this.$apollo.mutate({
      //       mutation: FEEDBACK,
      //       variables: formData
      //     }).then(() => {
      //       let onlyMetrik = {
      //         eventName: 'thanks',
      //       }
      //       this.$store.commit('hideModal');
      //       this.$router.push({name: "thanks", params: {onlyMetrik}});
      //     });
      //   } catch (e) {
      //     console.error(e);
      //   }
      // }
      },
    getCallbackOptions() {
      const hours = [];
      let currentHours = new Date().getHours();
      let isNight = false;
      if (currentHours < 9 || currentHours >= 21) {
        currentHours = 9;
        isNight = true;
      }
      const initialDate = new Date();
      initialDate.setHours(currentHours);
      while (currentHours < 21) {
        if (currentHours === initialDate.getHours() && initialDate.getMinutes() >= 50) {
          currentHours++;
          continue;
        }
        hours.push({
          title: `с ${currentHours}:00 до ${++currentHours}:00`,
        });
      }
      return isNight ? [...hours] : [{title: 'Как можно скорее'}, ...hours];
    }
  }
}
