
import {mapActions} from "vuex";

export default {
	props: {
		benefits: Array,
		hasModal: {
			type: Boolean,
			default: true
		},
		modal: {
			type: Boolean,
			default: false
		},
		position:{
			type: String,
			default: 'static'
		},
        catalog: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		...mapActions({
			openBenefit: 'benefits/openBenefit'
		})
	},
}
